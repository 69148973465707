<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.dns.record"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      new_date_json: {}, //数据结构
      multipleSelection: [], //复选框，数据
      is_edit: true, //是否可编辑
      is_delete: true, //是否可删除
      selection: true, //是否需要复选框
      radio: false, //单选变色
      space_color: false, //隔行变色
      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      domain: "",
      table_columns: [
        {
          prop: "name",
          label: this.$t("dns.record.host"),
          width: "200",
        },
        {
          prop: "type",
          label: this.$t("dns.record.type"),
          width: "100",
        },
        {
          prop: "content",
          label: this.$t("dns.record.value"),
          width: "300",
        },
        {
          prop: "prio",
          label: this.$t("dns.record.weght"),
          width: "50",
        },
        {
          prop: "ttl",
          label: this.$t("dns.record.ttl"),
          width: "100",
        },
        {
          prop: "change_date",
          label: this.$t("dns.record.change_date"),
          width: "180",
        },
      ],
      recordtype: [
        "A",
        "AAAA",
        "CNAME",
        "MX",
        "NS",
        "CAA",
        "TXT",
        "URL",
        "SRV",
      ],
      pagesizes: [10, 20, 30, 50, 100],
      pagesize: 10,
      recordtotal: 0,
      currentpage: 1,
      tempList: [],
      searchatext: "",
      recordlist: [],
      allrecordnumber: 0
    };
  },
  computed: {
    rows() {
      return this.recordlist.length;
    },
    searchrecord(){
      var datas=this.recordlist
      if(this.searchatext){
        datas=datas.filter(value => {
            return (!this.searchatext || value.name.toLowerCase().includes(this.searchatext.trim().toLowerCase())) ||
                  (!this.searchatext || value.content.toLowerCase().includes(this.searchatext.trim().toLowerCase()))
        })
      }
      return datas
    }
  },
  mounted() {
    this.title = this.$t("menuitems.dns.record");

    this.items = [
      {
        text: this.$t("menuitems.dns.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.dns.list"),
        href: "/dns/list",
      },
      {
        text: this.$route.params.domain + " " +this.$t("menuitems.dns.record"),
        active: true,
      },
    ];

    this.userinfo = localStorage.getItem("user");
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.domain = this.$route.params.domain;
    this.title = this.domain + " " + this.$t("menuitems.dns.record");
    this.getdnsrecord();
  },
  methods: {
    getreocrdtype(){
      var that = this;
      if (!that.domain) {
      } else {
        that.$axios
          .post(
            that.apiuri,
            {
              action: "getsupportrecordtype",
            },
            {
              headers: {
                Token: localStorage.getItem("user"),
              },
            }
          )
          .then(function (response) {
            that.loading = false;
            if (response.data.status == 200) {
              that.recordtype = response.data.data;
              that.getdnsrecord()
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getdnsrecord() {
      var that = this;
      if (!that.domain) {
      } else {
        that.$axios
          .post(
            that.apiuri,
            {
              action: "records",
              domain: that.domain,
            },
            {
              headers: {
                Token: localStorage.getItem("user"),
              },
            }
          )
          .then(function (response) {
            that.loading = false;
            if (response.data.status == 200) {
              that.recordlist = response.data.data;
              that.recordtype = response.data.recordtype;
              that.allrecordnumber = that.recordlist.length;
              that.handleCurrentChange(that.currentpage);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //隔行变色
    tableRowClassName() {
      //选取DOM节点
      var trs = this.$refs.mytable.$el
        .getElementsByTagName("tbody")[0]
        .getElementsByTagName("tr");
      for (var i in trs) {
        if (i % 2 == 0) {
          //当隔行变色未true时改变颜色
          if (this.space_color) {
            trs[i].style.backgroundColor = "#f0f9eb";
          } else {
            trs[i].style.backgroundColor = "";
          }
        }
      }
    },

    //多选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //编辑
    handleEdit(index, row) {
      row.edit = true;
    },
    //删除
    handleDelete(index, row) {
      var that = this;
      that.$confirm(
        that.$t("waring.tips.delete"),
        that.$t("waring.tips.text"),
        {
          confirmButtonText: that.$t("alter.button.ok"),
          cancelButtonText: that.$t("alter.button.cancel"),
          type: "warning",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              that.$axios
                .post(
                  that.apiuri,
                  {
                    action: "delete_record",
                    id: row.id,
                  },
                  {
                    headers: {
                      Token: localStorage.getItem("user"),
                    },
                  }
                )
                .then(function (response) {
                  instance.confirmButtonLoading = false;
                  done();
                  if (response.data.status == 200) {
                    // that.recordlist.splice(index, 1);
                    // that.handleCurrentChange(that.currentpage)
                    that.getdnsrecord()
                    that.$message({
                      message: that.$t("global.tips.alter.delete.success"),
                      type: "success",
                    });
                  } else if (response.data.status == 403) {
                    that.$message({
                      message: that.$t("global.tips.alter.auth.needauth"),
                      type: "waring",
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              done();
            }
          },
        }
      );
    },
    //取消
    handleCancel(index, row){
      row.edit = false;
    },
    //保存
    handleSave(index, row) {
      var that = this;
      let request = {};
      if (row.id) {
        request = { action: "edit_record" };
      } else {
        request = { action: "add_record", domain: this.domain };
      }
      var obj = Object.assign(request, row);
      row.loading = true;
      that.$axios
        .post(that.apiuri, obj, {
          headers: {
            Token: localStorage.getItem("user"),
          },
        })
        .then(function (response) {
          row.loading = false;

          delete that.recordlist[index].add;

          if (response.data.status == 200) {
            row.edit = false;
            if (request.action == "edit_record") {
              that.$message({
                message: that.$t("global.tips.alter.update.success"),
                type: "success",
              });
            } else {
              that.getdnsrecord();
              that.$message({
                message: that.$t("global.tips.alter.save.success"),
                type: "success",
              });
            }
          } else {
            that.$message({
              message: that.$t("global.tips.alter.update.failed"),
              type: "waring",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleAdd() {
      var addDataJson = {
        name: "",
        type: "",
        content: "",
        disable: 0,
        domain_id: this.recordlist[0].domain_id,
        loading: false,
        ttl: 600,
        prio: 0,
        vft: true,
      };
      addDataJson.edit = true;
      addDataJson.add = true;
      this.recordlist.unshift(addDataJson);
      this.handleCurrentChange(this.currentpage)
    },
    initAddDataJson(dataArray) {
      //新增时，初始化数据结构
      var dataJson = dataArray;
      var newDateJson = {};
      for (var key in dataJson) {
        if (key === "edit") {
          newDateJson[key] = "true";
        } else {
          newDateJson[key] = "";
        }
      }
      newDateJson["add"] = true;
      this.recordlist = newDateJson;
    },
    handleLock(index, row) {
      var that = this;
      let request = {
        action: "disable_record",
        id: row.id,
      };
      if (row.disabled == 0) {
        request.disable = 1;
      }
      row.loading = true;
      that.$axios
        .post(that.apiuri, request, {
          headers: {
            Token: localStorage.getItem("user"),
          },
        })
        .then(function (response) {
          row.loading = false;
          if (response.data.status == 200) {
            if (row.disabled == 0) {
              row.disabled = 1;
              that.$message({
                message: that.$t("global.tips.alter.disabled.success"),
                type: "success",
              });
            } else {
              row.disabled = 0;
              that.$message({
                message: that.$t("global.tips.alter.disabled.unlock"),
                type: "success",
              });
            }
          } else if (response.data.status == 403) {
            that.$message({
              message: that.$t("global.tips.alter.auth.needauth"),
              type: "waring",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dnssec() {
      this.$router.push({
        path: "/dns/" + this.domain + "/record/dnssec",
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.handleCurrentChange(this.currentpage)
      // console.log(this.tempList.slice((this.currentpage-1)*this.pagesize,this.currentpage*this.pagesize))
    },
    handleCurrentChange(val) {
      this.currentpage = val;
      let list = this.searchrecord;
      let from = (val - 1) * this.pagesize;
      let to = val * this.pagesize;
      this.tempList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tempList.push(list[from]);
        }
      }
    },
    handleSearchInput(){
      this.handleCurrentChange(this.currentpage)
    }
  },
  watch: {
    space_color: function () {
      //监听数据变化
      this.$nextTick(function () {
        /////方法
        this.tableRowClassName();
      });
    },
    recordlist: function () {
      //监听数据变化f
      this.$nextTick(function () {
        /////方法
        this.tableRowClassName();
      });
    },
  },
  filters: {
    formatTimer: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-button size="mini" type="primary" @click="handleAdd">{{
              $t("dns.record.add")
            }}</el-button>
            <el-button size="mini" type="success" @click="dnssec">{{
              $t("dns.record.dnssec")
            }}</el-button>
            <div class="col-12" style="margin-top: 15px">
              <div class="col-12">
                <el-input
                  v-model="searchatext"
                  size="small"
                  :placeholder="$t('dns.record.search')"
                  @input="handleSearchInput"
                ></el-input>
              </div>
            </div>

            <el-table
              ref="mytable"
              :data="tempList"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                v-if="radio"
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column
                v-if="selection"
                type="selection"
                width="50"
              ></el-table-column>
              <el-table-column
                align="center"
                v-for="(item, index, key) in table_columns"
                :item="item"
                :key="key"
                :index="index"
                :label="item.label"
                :width="item.width"
                min-width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    v-if="
                      scope.row.edit &&
                      (item.prop == 'name' ||
                        item.prop == 'content' ||
                        item.prop == 'ttl')
                    "
                    size="small"
                    v-model="scope.row[item.prop]"
                    :placeholder="'请输入' + item.label"
                  ></el-input>
                  <el-input
                    v-if="
                      scope.row.edit &&
                      item.prop == 'prio' &&
                      scope.row['type'] == 'MX'
                    "
                    size="small"
                    v-model="scope.row[item.prop]"
                    :placeholder="'请输入' + item.label"
                  ></el-input>
                  <el-input
                    v-if="
                      scope.row.edit &&
                      item.prop == 'prio' &&
                      scope.row['type'] != 'MX'
                    "
                    size="small"
                    disabled
                    v-model="scope.row[item.prop]"
                    :placeholder="'请输入' + item.label"
                  ></el-input>

                  <el-select
                    v-if="scope.row.edit && item.prop == 'type'"
                    v-model="scope.row[item.prop]"
                    filterable
                  >
                    <el-option
                      v-for="item in recordtype"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <span v-if="!scope.row.edit">{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('dns.record.action')" align="center">
                <template slot-scope="scope">
                  <!-- 全局控制的编辑 -->
                  <div
                    v-if="
                      is_edit && scope.row.add == undefined && scope.row.vft
                    "
                    style="display: inline-block"
                  >
                    <!-- 编辑 -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('dns.list.button.edit')"
                      placement="top-start"
                    >
                      <el-button
                        type="primary"
                        icon="el-icon-edit"
                        size="mini"
                        v-if="!scope.row.edit"
                        @click="handleEdit(scope.$index, scope.row)"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <!-- 保存 -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('dns.list.button.save')"
                      placement="top-start"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-check"
                        size="mini"
                        v-if="scope.row.edit"
                        :loading="scope.row.loading"
                        @click="handleSave(scope.$index, scope.row)"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <!-- 取消 -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('dns.list.button.cancel')"
                      placement="top-start"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-close"
                        size="mini"
                        v-if="scope.row.edit"
                        @click="handleCancel(scope.$index, scope.row)"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <!-- 添加控制 -->
                  <div
                    v-if="
                      scope.row.add != undefined &&
                      scope.row.add &&
                      scope.row.vft
                    "
                    style="display: inline-block"
                  >
                    <!-- 保存 -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('dns.list.button.save')"
                      placement="top-start"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-check"
                        size="mini"
                        v-if="scope.row.edit"
                        :loading="scope.row.loading"
                        @click="handleSave(scope.$index, scope.row)"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <!-- 取消 -->
                    <!-- <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('dns.list.button.cancel')"
                      placement="top-start"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-close"
                        size="mini"
                        v-if="scope.row.edit"
                        @click="handleCancel(scope.$index, scope.row)"
                        circle
                      ></el-button>
                    </el-tooltip> -->
                  </div>
                  <!-- 解析状态 -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('dns.list.button.suspend')"
                    placement="top-start"
                    v-if="scope.row.vft"
                  >
                    <el-button
                      type="warning"
                      icon="el-icon-video-pause"
                      size="mini"
                      :loading="scope.row.loading"
                      v-if="
                        scope.row.add == undefined && scope.row.disabled == 0
                      "
                      @click="handleLock(scope.$index, scope.row)"
                      circle
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('dns.list.button.unsuspend')"
                    placement="top-start"
                    v-if="scope.row.vft"
                  >
                    <el-button
                      type="info"
                      icon="el-icon-video-play"
                      size="mini"
                      :loading="scope.row.loading"
                      v-if="
                        scope.row.add == undefined && scope.row.disabled == 1
                      "
                      @click="handleLock(scope.$index, scope.row)"
                      circle
                    ></el-button>
                  </el-tooltip>
                  <!-- 全局控制删除 -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('dns.list.button.delete')"
                    placement="top-start"
                    v-if="scope.row.vft"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      v-if="is_delete && scope.row.add == undefined"
                      @click="handleDelete(scope.$index, scope.row)"
                      circle
                    ></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="text-center" style="margin-top:15px">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentpage"
                :page-sizes="pagesizes"
                :page-size.sync="pagesize"
                layout="sizes, prev, pager, next"
                :total="searchrecord.length"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.cell span{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 1;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;   
}
</style>