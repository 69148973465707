<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.dns.dnssec"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      loading: true,
      dnssec: [],
      dnssecstatus: 503,
      digest: "",
    };
  },
  computed: {
    rows() {
      return this.domainlist.length;
    },
  },
  mounted() {
    this.title = this.$t("menuitems.dns.dnssec");

    this.items = [
      {
        text: this.$t("menuitems.dns.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.dns.list"),
        href: "/dns/list",
      },
      {
        text: this.$route.params.domain,
        href: "/dns/" + this.$route.params.domain + "/record",
      },
      {
        text: this.$t("menuitems.dns.dnssec"),
        active: true,
      },
    ];

    this.userinfo = localStorage.getItem("user");
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.domain = this.$route.params.domain;
    this.title = this.domain + " " +this.$t("menuitems.dns.dnssec");
    this.getdnssec();
  },
  methods: {
    getdnssec() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "dnssec_status",
            domain: that.$utils.encryptv2(that.domain, that.aeskey),
          },
          {
            headers: {
              Token: localStorage.getItem("user"),
            },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.dnssecstatus = response.data.status;
          if (response.data.status == 200) {
            that.dnssec = response.data.dnssec;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    copytext(dom) {
      let clipboard = new this.clipboard("." + dom);
      clipboard.on("success", function () {
        this.$toast(this.$t("global.copy.success"));
      });
      clipboard.on("error", function () {
        this.$toast(this.$t("global.copy.fail"));
      });
    },
    enablednssec() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "enable_dnssec",
            domain: that.$utils.encryptv2(that.domain, that.aeskey),
          },
          {
            headers: {
              Token: localStorage.getItem("user"),
            },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.dnssecstatus = response.data.status;
          if (response.data.status == 200) {
            that.dnssec = response.data.dnssec;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    disablednssec() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "disable_dnssec",
            domain: that.$utils.encryptv2(that.domain, that.aeskey),
          },
          {
            headers: {
              Token: localStorage.getItem("user"),
            },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.getdnssec();
            that.$message({
              message: that.$t("global.tips.alter.dnssec.disablesuccess"),
              type: "success",
            });
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  filters: {
    formatTimer: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-button
              type="primary"
              round
              v-if="dnssecstatus == 503"
              @click="enablednssec"
              >{{ $t("dns.dnssec.enable") }}</el-button
            >
            <el-button
              type="danger"
              round
              v-if="dnssecstatus == 200"
              @click="disablednssec"
              >{{ $t("dns.dnssec.disable") }}</el-button
            >
            <table class="table table-striped" style="margin-top: 25px">
              <tbody>
                <tr>
                  <td>{{ $t("dns.dnssec.tag") }}(tag)</td>
                  <td>{{ dnssec.tag }}</td>
                </tr>
                <tr>
                  <td>{{ $t("dns.dnssec.algorithm") }}(algo)</td>
                  <td>{{ dnssec.algo }}-{{ dnssec.algotype }}</td>
                </tr>
                <tr>
                  <td>{{ $t("dns.dnssec.digest") }}(digest type)</td>
                  <td>
                    <el-select v-model="digest">
                      <el-option label="SHA1" value="sha1"> </el-option>
                      <el-option label="SHA256" value="sha256"> </el-option>
                      <el-option label="SHA-384" value="sha384"> </el-option>
                    </el-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("dns.dnssec.digestvalue") }}(digest)</td>
                  <td v-if="digest">
                    {{ dnssec.digest[digest] }}
                  </td>
                  <td v-else></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>