export const menuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.dns.text',
        icon: 'ri-global-line',
        subItems: [
            {
                id: 300,
                label: 'menuitems.dns.list',
                link: '/dns/list'
            },
            {
                id: 301,
                label: 'menuitems.dns.ptr',
                link: '/dns/ptr'
            },
            {
                id: 302,
                label: 'menuitems.dns.private',
                link: '/dns/private'
            }
        ]
    },
    {
        id: 4,
        label: 'menuitems.ssl.text',
        icon: 'ri-lock-line',
        subItems: [
            {
                id: 400,
                label: 'menuitems.ssl.manage',
                link: '/ssl/manage'
            },
            {
                id: 401,
                label: 'menuitems.ssl.shop',
                link: '/ssl/shop'
            }
        ]
    },
    {
        id: 5,
        label: 'menuitems.cdn.text',
        icon: 'ri-links-line',
        subItems: [
            {
                id: 500,
                label: 'menuitems.cdn.home',
                link: '/cdn/index'
            },
            {
                id: 501,
                label: 'menuitems.cdn.domain',
                link: '/cdn/domain'
            },
            {
                id: 502,
                label: 'menuitems.cdn.plugins',
                link: '/cdn/plugins'
            },
            {
                id: 503,
                label: 'menuitems.cdn.log',
                link: '/cdn/log'
            },
            {
                id: 504,
                label: 'menuitems.cdn.secure',
                link: '/cdn/secure'
            }
        ]
    },
    {
        id: 9,
        label: 'menuitems.setting.text',
        icon: 'ri-file-settings-line',
        subItems: [
            {
                id: 900,
                label: 'menuitems.setting.account',
                link: '/setting/account'
            },
            {
                id: 901,
                label: 'menuitems.setting.safety',
                link: '/setting/safety'
            },
            {
                id: 902,
                label: 'menuitems.setting.actionlog',
                link: '/setting/logs'
            }
        ]
    },
    {
        id: 18,
        isLayout: true
    }
]