<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.dns.list"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
      domainlist: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      fields: [
        { key: "domain", label: this.$t("dns.list.domain") },
        { key: "count", label: this.$t("dns.list.count") },
        { key: "last", sortable: true, label: this.$t("dns.list.lastday") },
      ],
    };
  },
  computed: {
    rows() {
      return this.domainlist.length;
    },
  },
  mounted() {
    this.title = this.$t("menuitems.dns.list");

    this.items = [
      {
        text: this.$t("menuitems.dns.text"),
        href: "/",
      },

      {
        text: this.$t("menuitems.dns.list"),
        active: true,
      },
    ];

    this.userinfo = localStorage.getItem("user");
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getdomainlist();
  },
  methods: {
    getdomainlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "domains",
          },
          {
            headers: {
              Token: localStorage.getItem("user"),
            },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.loading = false;
            that.domainlist = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    torecords(domain) {
      this.$router.push({
        path: "/dns/" + domain + "/record",
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    adddomain() {
      var that = this;
      that
        .$prompt(
          that.$t("waring.tips.input.domain"),
          that.$t("waring.tips.text"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
          }
        )
        .then(({ value }) => {
          if (value.length > 0) {
            that.loading = true;
            that.$axios
              .post(
                that.apiuri,
                {
                  action: "adddomain",
                  domain: value,
                },
                {
                  headers: {
                    Token: localStorage.getItem("user"),
                  },
                }
              )
              .then(function (response) {
                that.loading = false;
                that.getdomainlist();
                if (response.data.status == 200) {
                  
                } else if (response.data.status == 402) {
                  that.$message({
                    message: that.$t("dns.domain.exit"),
                    type: "error",
                  });
                } else if (response.data.status == 300) {
                  that.$message({
                    message: that.$t("dns.domain.format"),
                    type: "error",
                  });
                } else {
                  that.$message({
                    message: that.$t("error.request.500"),
                    type: "error",
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(() => {});
    },
    deletedomain(domain_id, smscode) {
      var that = this;
      that.$confirm(
        that.$t("waring.tips.deletedomain"),
        that.$t("waring.tips.text"),
        {
          confirmButtonText: that.$t("alter.button.ok"),
          cancelButtonText: that.$t("alter.button.cancel"),
          type: "warning",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              that.$axios
                .post(
                  that.apiuri,
                  {
                    action: "delete_domain",
                    id: domain_id,
                    smscode: that.$utils.encryptv2(smscode, that.aeskey),
                  },
                  {
                    headers: {
                      Token: localStorage.getItem("user"),
                    },
                  }
                )
                .then(function (response) {
                  instance.confirmButtonLoading = false;
                  if (response.data.status == 200) {
                    done();
                    that.$message({
                      message: that.$t("global.tips.alter.delete.success"),
                      type: "success",
                      onClose: () => {
                        that.getdomainlist();
                      },
                    });
                  } else if (response.data.status == 403) {
                    done();
                    that.$message({
                      message: that.$t("global.tips.alter.auth.needauth"),
                      type: "warning",
                      onClose: () => {
                        that
                          .$prompt(
                            that.$t("global.tips.alter.auth.inputsms"),
                            that.$t("global.tips.alter.auth.needauth"),
                            {
                              confirmButtonText: that.$t("alter.button.ok"),
                              cancelButtonText: that.$t("alter.button.cancel"),
                            }
                          )
                          .then(({ value }) => {
                            if (value.length == 6) {
                              that.deletedomain(domain_id, value);
                            } else {
                              that.$message({
                                message: that.$t("global.sms.format"),
                                type: "warning",
                              });
                            }
                          });
                      },
                    });
                  } else if (response.data.status == 408) {
                    that.$message({
                      message: that.$t("global.sms.busy"),
                      type: "warning",
                    });
                  } else if (response.data.status == 405) {
                    that.$message.error(that.$t("error.sms.code"));
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              done();
            }
          },
        }
      );
    },
    suspend(domain_id,status) {
      var that = this;
      let msg=that.$t("waring.tips.suspend");
      if(status) msg=that.$t("waring.tips.recovery");
      that.$confirm(
        msg,
        that.$t("waring.tips.text"),
        {
          confirmButtonText: that.$t("alter.button.ok"),
          cancelButtonText: that.$t("alter.button.cancel"),
          type: "warning",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              that.$axios
                .post(
                  that.apiuri,
                  {
                    action: "suspend_domain",
                    id: domain_id,
                  },
                  {
                    headers: {
                      Token: localStorage.getItem("user"),
                    },
                  }
                )
                .then(function (response) {
                  instance.confirmButtonLoading = false;
                  done();
                  that.getdomainlist()
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("global.tips.alter.suspend.success"),
                      type: "success",
                    });
                  } else if (response.data.status == 201) {
                    that.$message({
                      message: that.$t("global.tips.alter.recovery.success"),
                      type: "success",
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              done();
            }
          },
        }
      );
    },
  },
  filters: {
    formatTimer: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div>
              <el-button type="primary" plain @click="adddomain">{{
                $t("dns.domain.addnew")
              }}</el-button>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t("dns.list.domain") }}</th>
                  <th>{{ $t("dns.list.count") }}</th>
                  <th>{{ $t("dns.list.status") }}</th>
                  <th>{{ $t("dns.list.lastday") }}</th>
                  <th>{{ $t("dns.list.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in domainlist" :key="idx">
                  <td><el-link type="primary" @click="torecords(val.domain)">{{ val.domain }}</el-link></td>
                  <td>{{ val.count }}</td>
                  <td v-if="val.enable == 0 && val.status == 0">
                    {{ $t("dns.domain.status.ok") }}
                  </td>
                  <td v-if="val.enable == 1 && val.status == 0">
                    {{ $t("dns.domain.status.supu") }}
                  </td>
                  <td v-if="val.enable == 1 && val.status == 1">
                    {{ $t("dns.domain.status.lock") }}
                  </td>
                  <td v-if="val.enable == 1 && val.status == 2">
                    {{ $t("dns.domain.status.disabled") }}
                  </td>
                  <td>{{ val.last }}</td>
                  <td>
                    <el-button
                      type="primary"
                      size="small"
                      @click="torecords(val.domain)"
                      plain
                      >{{ $t("dns.domain.manguage") }}</el-button
                    >
                    <el-button
                      type="warning"
                      size="small"
                      @click="suspend(val.id,false)"
                      plain
                      v-if="val.enable == 0 && val.status == 0"
                      >{{ $t("dns.domain.status.supu") }}</el-button
                    >
                    <el-button
                      type="info"
                      size="small"
                      @click="suspend(val.id,true)"
                      plain
                      v-if="val.enable == 1 && val.status == 0"
                      >{{ $t("dns.domain.status.recovery") }}</el-button
                    >
                    <el-button
                      type="danger"
                      size="small"
                      @click="deletedomain(val.id, 0)"
                      plain
                      >{{ $t("dns.domain.delete") }}</el-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>