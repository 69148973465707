<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.setting.account"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      
      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.setting.account");

    this.items = [
      {
        text: this.$t("dashboard.text"),
        href: "/",
      },

      {
        text: this.$t("menuitems.setting.account"),
        active: true,
      },
    ];
    
    
    this.userinfo = localStorage.getItem("user");
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
  },
  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading"></div>
        </div>
      </div>
    </div>
  </Layout>
</template>