import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/setting/account',
        name: 'accountsetting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/account')
    },
    {
        path: '/setting/safety',
        name: 'accountsafety',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/setting/safety')
    },
    // {
    //     path: '/setting/logs',
    //     name: 'actionlogs',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/setting/logs')
    // },
    {
        path: '/dns/list',
        name: 'dnslist',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dns/list')
    },
    {
        path: '/dns/:domain/record',
        name: 'domainrecord',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dns/record')
    },
    {
        path: '/dns/:domain/record/dnssec',
        name: 'dnssec',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dns/dnssec')
    },
    {
        path: '/dns/ptr',
        name: 'ipptr',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dns/ptrlist')
    },
    {
        path: '/dns/ptr/:id',
        name: 'ipptrrecord',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dns/ptr')
    },
    {
        path: '*',
        component: () => import('../views/pages/extend/404')
    }

]
