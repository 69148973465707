var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"card-body"},[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(_vm._s(_vm.$t("dns.record.add")))]),_c('el-button',{attrs:{"size":"mini","type":"success"},on:{"click":_vm.dnssec}},[_vm._v(_vm._s(_vm.$t("dns.record.dnssec")))]),_c('el-table',{ref:"mytable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tempList},on:{"selection-change":_vm.handleSelectionChange}},[(_vm.radio)?_c('el-table-column',{attrs:{"type":"index","width":"50"}}):_vm._e(),(_vm.selection)?_c('el-table-column',{attrs:{"type":"selection","width":"50"}}):_vm._e(),_vm._l((_vm.table_columns),function(item,index,key){return _c('el-table-column',{key:key,attrs:{"align":"center","item":item,"index":index,"label":item.label,"width":item.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                    scope.row.edit &&
                    (item.prop == 'name' ||
                      item.prop == 'content' ||
                      item.prop == 'ttl')
                  )?_c('el-input',{attrs:{"size":"small","placeholder":'请输入' + item.label},model:{value:(scope.row[item.prop]),callback:function ($$v) {_vm.$set(scope.row, item.prop, $$v)},expression:"scope.row[item.prop]"}}):_vm._e(),(
                    scope.row.edit &&
                    item.prop == 'prio' &&
                    scope.row['type'] == 'MX'
                  )?_c('el-input',{attrs:{"size":"small","placeholder":'请输入' + item.label},model:{value:(scope.row[item.prop]),callback:function ($$v) {_vm.$set(scope.row, item.prop, $$v)},expression:"scope.row[item.prop]"}}):_vm._e(),(
                    scope.row.edit &&
                    item.prop == 'prio' &&
                    scope.row['type'] != 'MX'
                  )?_c('el-input',{attrs:{"size":"small","disabled":"","placeholder":'请输入' + item.label},model:{value:(scope.row[item.prop]),callback:function ($$v) {_vm.$set(scope.row, item.prop, $$v)},expression:"scope.row[item.prop]"}}):_vm._e(),(scope.row.edit && item.prop == 'type')?_c('el-select',{attrs:{"filterable":""},model:{value:(scope.row[item.prop]),callback:function ($$v) {_vm.$set(scope.row, item.prop, $$v)},expression:"scope.row[item.prop]"}},_vm._l((_vm.recordtype),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1):_vm._e(),(!scope.row.edit)?_c('span',[_vm._v(_vm._s(scope.row[item.prop]))]):_vm._e()]}}],null,true)})}),_c('el-table-column',{attrs:{"label":_vm.$t('dns.record.action'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                    _vm.is_edit && scope.row.add == undefined && scope.row.vft
                  )?_c('div',{staticStyle:{"display":"inline-block"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('dns.list.button.edit'),"placement":"top-start"}},[(!scope.row.edit)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit","size":"mini","circle":""},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}}):_vm._e()],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('dns.list.button.save'),"placement":"top-start"}},[(scope.row.edit)?_c('el-button',{attrs:{"type":"success","icon":"el-icon-check","size":"mini","loading":scope.row.loading,"circle":""},on:{"click":function($event){return _vm.handleSave(scope.$index, scope.row)}}}):_vm._e()],1)],1):_vm._e(),(
                    scope.row.add != undefined &&
                    scope.row.add &&
                    scope.row.vft
                  )?_c('div',{staticStyle:{"display":"inline-block"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('dns.list.button.save'),"placement":"top-start"}},[(scope.row.edit)?_c('el-button',{attrs:{"type":"success","icon":"el-icon-check","size":"mini","loading":scope.row.loading,"circle":""},on:{"click":function($event){return _vm.handleSave(scope.$index, scope.row)}}}):_vm._e()],1)],1):_vm._e(),(scope.row.vft)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('dns.list.button.suspend'),"placement":"top-start"}},[(
                      scope.row.add == undefined && scope.row.disabled == 0
                    )?_c('el-button',{attrs:{"type":"warning","icon":"el-icon-video-pause","size":"mini","loading":scope.row.loading,"circle":""},on:{"click":function($event){return _vm.handleLock(scope.$index, scope.row)}}}):_vm._e()],1):_vm._e(),(scope.row.vft)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('dns.list.button.unsuspend'),"placement":"top-start"}},[(
                      scope.row.add == undefined && scope.row.disabled == 1
                    )?_c('el-button',{attrs:{"type":"info","icon":"el-icon-video-play","size":"mini","loading":scope.row.loading,"circle":""},on:{"click":function($event){return _vm.handleLock(scope.$index, scope.row)}}}):_vm._e()],1):_vm._e(),(scope.row.vft)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('dns.list.button.delete'),"placement":"top-start"}},[(_vm.is_delete && scope.row.add == undefined)?_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"mini","circle":""},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}}):_vm._e()],1):_vm._e()]}}])})],2),_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"current-page":_vm.currentpage,"page-sizes":_vm.pagesizes,"page-size":_vm.pagesize,"layout":"sizes, prev, pager, next","total":_vm.recordtotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentpage=$event},"update:current-page":function($event){_vm.currentpage=$event}}})],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }