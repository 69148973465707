<script>
import i18n from "@/i18n";

export default {
  page() {
    return {
      title: this.$t("title.login"),
    };
  },
  data() {
    return {
      form: {
        country: "+86",
        phone: "",
        password: "",
        remember: false,
        smscode: "",
      },
      loading: false,
      smsviod: false,
      resendsms: false,
      smscodedisable: false,
      coutdown: 60,
      smstimeer: "",
      sendsmsloading: false,
      uri: "",
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  mounted() {
    var languagecode = navigator.language;
    languagecode = languagecode.substr(0, 2);
    i18n.locale = languagecode;
    this.uri = window.location.href;
  },
  methods: {
    tryToLogIn() {
      var that = this;
      if (that.form.phone.length <= 5) {
        that.$message({
          message: that.$t("waring.noinput.phone"),
          type: "warning",
        });
        return;
      }
      if (that.form.password.length <= 5) {
        that.$message({
          message: that.$t("waring.noinput.password"),
          type: "warning",
        });
        return;
      }
      let iv = that.$utils.getivstring(16);
      that.loading = true;
      that.$axios
        .post(this.apiuri, {
          action: "login",
          country: that.form.country,
          phone: {
            text: that.$utils.encrypt(that.form.phone, that.aeskey, iv),
            iv: iv,
          },
          password: {
            text: that.$utils.encrypt(that.form.password, that.aeskey, iv),
            iv: iv,
          },
          remember: that.remember,
          smscode: {
            text: that.$utils.encrypt(that.form.smscode, that.aeskey, iv),
            iv: iv,
          },
        })
        .then(function (response) {
          that.loading = false;
          let data = response.data;
          if (data.status == 405)
            that.$message.error(that.$t("error.sms.code"));
          if (data.status == 404)
            that.$message.error(that.$t("error.account.notfound"));
          if (data.status == 403) {
            if (that.sendsmsloading) {
              if (data.sms) {
                that.coutdown = 60;
                that.timer();
              }
              that.sendsmsloading = false;
            } else {
              that.$message({
                message: that.$t("waring.recheck.sms"),
                type: "warning",
              });
              if (data.sms) {
                that.smsviod = true;
                that.timer();
              }
            }
          }
          if (data.status == 200) {
            localStorage.setItem("user", data.token);
            that.$router.push({ name: "home" });
          }
        })
        .catch(function (error) {
          console.log(error);
          that.loading = false;
        });
    },
    sendsms() {
      var that = this;
      if (that.coutdown == 0) {
        that.form.smscode = "";
        that.tryToLogIn();
      }
    },
    timer() {
      this.resendsms = true;
      this.sendsmsloading = false;
      this.smstimeer = setInterval(() => {
        this.coutdown--;
        if (this.coutdown === 0) {
          this.resendsms = false;

          clearInterval(this.smstimeer);
        }
      }, 1000);
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="https://aic.xin/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-6" v-loading="loading">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo_dark.png"
                              width="50%"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">
                          {{ $t("account.login.text") }}
                        </h4>
                        <p class="text-muted"></p>
                      </div>
                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <el-form ref="form" :model="form" v-if="!smsviod">
                            <el-form-item>
                              <el-input
                                :placeholder="$t('system.tips.input.phone')"
                                v-model="form.phone"
                                class="input-with-select"
                              >
                                <el-select
                                  v-model="form.country"
                                  slot="prepend"
                                  :placeholder="
                                    $t('system.tips.select.country')
                                  "
                                >
                                  <el-option value="+86"></el-option>
                                  <el-option value="+81"></el-option>
                                  <el-option value="+1"></el-option>
                                </el-select>
                              </el-input>
                            </el-form-item>
                            <el-form-item>
                              <el-input
                                :placeholder="$t('system.tips.input.password')"
                                v-model="form.password"
                                show-password
                              ></el-input>
                            </el-form-item>
                          </el-form>
                          <el-form ref="form" :model="form" v-if="smsviod">
                            <el-form-item>
                              <el-input
                                :placeholder="$t('account.register.smscode')"
                                v-model="form.smscode"
                                class="input-with-select"
                                :disabled="smscodedisable"
                              >
                                <el-button
                                  slot="append"
                                  @click="sendsms"
                                  :loading="sendsmsloading"
                                >
                                  <span v-if="!resendsms">{{
                                    $t("account.register.sendsms")
                                  }}</span>
                                  <span v-else>
                                    {{ coutdown }}
                                  </span>
                                </el-button>
                              </el-input>
                            </el-form-item>
                          </el-form>

                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                              v-model="form.remember"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                              >{{ $t("account.login.remember") }}</label
                            >
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              {{ $t("account.login.text") }}
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          {{ $t("account.register.noaccount") }}
                          <a
                            :href="'https://reg.aic.ltd/?ref=' + uri"
                            class="font-weight-medium text-primary"
                            >{{ $t("account.register.text") }}</a
                          >
                        </p>
                        <p>
                          © 2016 Shijiazhuang MOE Information Technology Co.,
                          Ltd
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.el-select .el-input {
  width: 100px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>