import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import { Stepper, SubmitBar, PasswordInput, NumberKeyboard, Toast, Cell, CellGroup, Collapse, CollapseItem, RadioGroup, Radio, Divider, Card } from 'vant';
import ElementUI from 'element-ui'
import clipboard from 'clipboard'
import vco from "v-click-outside"
import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import axios from 'axios'
import VueEllipseProgress from 'vue-ellipse-progress'
import "@/assets/scss/app.scss";

// import Vconsole from 'vconsole';
import 'element-ui/lib/theme-chalk/index.css'
import Utils from './utils'
const Base64 = require('js-base64').Base64
const Swal = require('sweetalert2')
import 'sweetalert2/src/sweetalert2.scss'
// new Vconsole();

Vue.prototype.$axios = axios;
Vue.prototype.clipboard = clipboard;
Vue.prototype.apiuri = "https://api.aicodns.com/api.json";
Vue.config.productionTip = false
Vue.prototype.version = process.env.version
Vue.prototype.aeskey = "29xf2OGBGHqWNn7xZExtQuLFic0V8JMp"
Vue.prototype.$utils = Utils;
Vue.prototype.Base64 = Base64;
Vue.prototype.$swal = Swal

Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Divider);
Vue.use(Card);
Vue.use(Stepper);
Vue.use(SubmitBar);

Vue.use(VueEllipseProgress)
Vue.use(ElementUI)

Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.component('apexchart', VueApexCharts)


new Vue({
  router,
  store,
  i18n,
  data: function () {
    return {

      ismobile: false,
    }
  },
  render: h => h(App)
}).$mount('#app')
