<script>
import { required, email } from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
export default {
  page() {
    return {
      title: this.$t("account.register.text"),
    };
  },
  data() {
    return {
      user: { username: "", email: "", password: "" },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      form: {
        country: "+86",
        phone: "",
        password: "",
        remember: false,
        smscode: "",
      },
      loading: false,
      smsviod: false,
      resendsms: false,
      smscodedisable: false,
      coutdown: 60,
      smstimeer: "",
      sendsmsloading: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      username: { required },
      email: { required, email },
      password: { required }
    }
  },
  mounted() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.register({
              email: this.user.email,
              password: this.user.password
            })
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                if (this.registerSuccess) {
                  this.$router.push(
                    this.$route.query.redirectFrom || { name: "home" }
                  );
                }
              })
              .catch(error => {
                this.tryingToRegister = false;
                this.regError = error ? error : "";
                this.isRegisterError = true;
              })
          );
        } else {
          const { email, username, password } = this.user;
          if (email && username && password) {
            this.registeruser(this.user);
          }
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-6">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo_dark.png"
                              width="50%"
                              alt="logo"
                            />
                          </a>
                        </div>
                        <h4 class="font-size-18 mt-4">{{ $t("account.register.button") }}</h4>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >{{ $t("account.register.success") }}</b-alert>

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                        >{{regError}}</b-alert>

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>
                        
                        <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                          <el-form ref="form" :model="form" v-if="!smsviod">
                            <el-form-item>
                              <el-input
                                :placeholder="$t('system.tips.input.phone')"
                                v-model="form.phone"
                                class="input-with-select"
                              >
                                <el-select
                                  v-model="form.country"
                                  slot="prepend"
                                  :placeholder="
                                    $t('system.tips.select.country')
                                  "
                                >
                                  <el-option value="+86"></el-option>
                                  <el-option value="+81"></el-option>
                                  <el-option value="+1"></el-option>
                                </el-select>
                              </el-input>
                            </el-form-item>
                            <el-form-item>
                              <el-input
                                :placeholder="$t('system.tips.input.password')"
                                v-model="form.password"
                                show-password
                              ></el-input>
                            </el-form-item>
                            <el-form-item>
                              <el-input
                                :placeholder="$t('system.tips.input.repassword')"
                                v-model="form.repassword"
                                show-password
                              ></el-input>
                            </el-form-item>
                          </el-form>


                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >{{ $t("account.register.button") }}</button>
                          </div>

                          <div class="mt-4 text-center">
                            <p class="mb-0">
                              <a
                                href="#"
                                class="text-primary"
                              >{{ $t("account.register.termsservice") }}</a>
                            </p>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          {{ $t("account.register.haveaccount") }}
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >{{ $t("account.register.login") }}</router-link>
                        </p>
                        <p>
                          © 2016 Shijiazhuang MOE Information Technology Co., Ltd
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.el-select .el-input {
  width: 100px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>